export const Odometer = (function(win, doc) {  
    class OdometerFn {  
        constructor(x, y) {  
            this.setting = {  
                len: null, // 默认最小位数  
                speed: 2500, // 动画速度  
                num: "", // 初始化值  
                symbol: '', // 默认的分割符号，千，万，千万  
                dot: 0, // 保留几位小数点   
                zero: true  
            };  
            this.$parent = doc.querySelector(x);  
            this.html = `<div class="number-animate-dom" data-num="{{num}}">  
                            <span class="number-animate-span">0</span>  
                            <span class="number-animate-span">1</span>  
                            <span class="number-animate-span">2</span>  
                            <span class="number-animate-span">3</span>  
                            <span class="number-animate-span">4</span>  
                            <span class="number-animate-span">5</span>  
                            <span class="number-animate-span">6</span>  
                            <span class="number-animate-span">7</span>  
                            <span class="number-animate-span">8</span>  
                            <span class="number-animate-span">9</span>  
                            <span class="number-animate-span">0</span>  
                            <span class="number-animate-span">.</span>  
                        </div>`;  
            this.extend(this.setting, y);  
            this.init(this.$parent);  
        }  

        init(x) {  
            x.innerHTML = this.setNumDom(this.numToArr(this.setting.num));  
            this.animate(x);  
        }  

        animate($parent) { // 执行动画  
            const $dom = $parent.querySelectorAll('.number-animate-dom');  
            $dom.forEach(o => {  
                let num = o.getAttribute('data-num');  
                if (this.setting.zero && num == 0) num = 10; // 修改-零值处理  
                this._height = o.offsetHeight / 12;  
                o.style['transform'] = `translateY(${num == "." ? -11 * this._height : -num * this._height}px)`;  
                o.style['transition'] = (num == "." ? 0 : this.setting.speed / 1000) + 's';  
            });  
        }  

        setNumDom(arrStr) { // 生成数字DOM  
            let shtml = '<div class="number-animate">';  
            arrStr.forEach((o, i) => {  
                if (i != 0 && (arrStr.length - i) % 3 === 0 && this.setting.symbol !== "" && o !== ".") {  
                    shtml += `<div class="number-animate-dot"><span>${this.setting.symbol}</span></div>${this.html.replace("{{num}}", o)}`;  
                } else {  
                    shtml += this.html.replace("{{num}}", o);  
                }  
            });  
            shtml += '</div>';  
            return shtml;  
        }  

        update(num) {  
            let newArr = this.numToArr(num);  
            const $dom = this.$parent.querySelectorAll(".number-animate-dom");  
            if ($dom.length !== newArr.length) {  
                this.$parent.innerHTML = this.setNumDom(newArr);  
            } else {  
                $dom.forEach((o, i) => {  
                    o.setAttribute('data-num', newArr[i]);  
                });  
            }  
            this.animate(this.$parent);  
            this.init(this.$parent);  // 重新初始化
        }  

        reset(num) { // 重置 Odometer  
            if (num !== undefined) {  
                this.setting.num = num;  // 更新数值  
            }  
            this.init(this.$parent);  // 重新初始化  
        }  

        numToArr(num) {  
            num = parseFloat(num).toFixed(this.setting.dot);  
            // console.log('122222',num);
            let arrStr = num.toString().split("");  
            const arrLen = arrStr.length;  
            if (arrLen < this.setting.len) { // 添加零前缀  
                arrStr = Array(this.setting.len - arrLen).fill(0).concat(arrStr);  
            }  
            return arrStr;  
        }  

        extend(target, source) { // 目标对象扩展  
            for (let key in source) {  
                if (source.hasOwnProperty(key)) {  
                    target[key] = source[key];  
                }  
            }  
        }  
    }  
    return OdometerFn;  
})(window, document);  