module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer class="btm-white">\r\n  <div class="footer container">\r\n    <div class="footer-logo"><a href="index.html">\r\n      <img src="' +
((__t = (require('../../assets/images/logo.png'))) == null ? '' : __t) +
'" alt="">\r\n      <img src="' +
((__t = (require('../../assets/images/logo_lubao.png'))) == null ? '' : __t) +
'" alt="">\r\n    </a></div>\r\n    <div class="footer-info">\r\n      <div class="footer-nav">\r\n        <div><a href="shop_index.html" class="ani-line">Tire Solution</a></div>\r\n        <div><a href="list_hse.html" class="ani-line">HSE</a></div>\r\n        <div><a href="about.html" class="ani-line">About Us</a></div>\r\n        <div><a href="news_index.html" class="ani-line">News</a></div>\r\n      </div>\r\n      <div class="footer-contact">\r\n        <div class="footer-info-title">Contact Us</div>\r\n        <p>Tel:<a href="tel:+86-532-85831197">+86-532-85831197</a> / <a href="tel:+86-532-85831697">85831697</a></p>\r\n        <p>Mail:<a href="mailto:info@qihangtyre.com.cn">info@qihangtyre.com.cn</a></p>\r\n      </div>\r\n      <div class="footer-social">\r\n        <!-- <div class="footer-info-title">Follow us</div>\r\n        <div class="icon">\r\n          <a href="#" target="_blank"><i class="iconfont icon-facebook"></i></a>\r\n          <a href="#" target="_blank"><i class="iconfont icon-lingying"></i></a>\r\n        </div> -->\r\n        <div class="ewm-img">\r\n          <div class="item">\r\n            <a href="#"><img src="' +
((__t = (require('../../assets/images/weixin.png'))) == null ? '' : __t) +
'" alt=""></a>\r\n            <p>微信公众号二维码</p>\r\n          </div>\r\n          <div class="item">\r\n            <a href="#"><img src="' +
((__t = (require('../../assets/images/website.png'))) == null ? '' : __t) +
'" alt=""></a>\r\n            <p>官方网站二维码</p>\r\n          </div>\r\n        </div>\r\n        <div class="link">\r\n          <a href="privacy.html" class="ani-line">Privacy Policy</a>\r\n          <a href="privacy.html" class="ani-line">Cookie Policy</a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="copyright container">\r\n    <div class="left">© <script language="JavaScript">var today = new Date();document.write(today.getFullYear())</script> <a href="/">Qingdao Qihang Tyre CO., Ltd</a>  All Rights Reserved.</div>\r\n    <div class="right">\r\n      <a href="javascript:void(0)" data-open="map" class="site-map">Sitemap</a><span class="site-map">|</span><a href="https://www.leadto.com.cn/" target="_blank">Designed by LTD</a>\r\n    </div>\r\n  </div>\r\n</footer>\r\n<!-- 隐私正常弹窗 -->\r\n<div class="cookie-consent hide">\r\n  <p>Dear visitor,​</p>\r\n  <p>We use cookies to optimise and continuously improve our website for individual visitor like you.  Click “Accept” to activate them or “Reject” if you do not want them. Please see  our  <a href="/Cookies-Policy">Cookies Policy</a> to learn more about how they are used on our website. You can change settings here at your convenience.​</p>\r\n  <div class="btn-container">\r\n    <a class="reject button" href="javascript:void(0);">Reject</a>\r\n    <a class="accept button" href="javascript:void(0);">Accept</a>\r\n  </div>\r\n</div>';

}
return __p
}